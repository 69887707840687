
import DatePicker from "@/components/utils/IDatePicker.vue";
import ValidationMixin from "@/mixins/validation";
import {
  UserDepartment,
  DepartmentTypes,
} from "@/store/modules/department/department.types";
import { DefaultState } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const departmentX = namespace("Department");

@Component({
  components: { DatePicker },
})
export default class EditDepartment extends Mixins(ValidationMixin) {
  @departmentX.State(DepartmentTypes.UPDATE_DEPARTMENT_STATE)
  public updateDepartment!: DefaultState;

  @departmentX.State(DepartmentTypes.DEPARTMENT_DATA)
  public userDepartment!: UserDepartment;

  @departmentX.Mutation(DepartmentTypes.SET_UPDATE_DEPARTMENT_DIALOG)
  public setUpdateDepartment!: (updateDepartment: boolean) => void;

  @departmentX.Action(DepartmentTypes.UPDATE_DEPARTMENT)
  public saveDepartment!: (farmer: UserDepartment) => Promise<void>;

  public updateDepartmentStep = 1;

  public isValid = false;

  public department: UserDepartment = {
    id: -1,
    name: "",
    description: "",
    active: false,
    createdAt: new Date(),
  };

  @Ref("form") public formRef!: Validator;

  closeUpdateDepartmentDialog(): void {
    this.setUpdateDepartment(false);
  }

  async saveDepartmentToDb(): Promise<void> {
    await this.formRef.validate();

    if (this.isValid) {
      await this.saveDepartment(this.department);
    }
  }

  @Watch("userDepartment")
  onDataChanged(value?: UserDepartment): void {
    this.department = Object.assign({}, value);
  }

  mounted(): void {
    this.department = Object.assign({}, this.userDepartment);
  }
}
